<template>
    <div id="team">
        <PageTitle :title="title" />
        <div class="container">
            <div class="team">
                <div class="featured-members">
                    <div v-for="(member, i) in featuredTeam('special')" :key="i" class="member">
                        <router-link
                            v-if="member.attributes.Slug === 'jose-cerda-zein' || member.attributes.Slug === 'nancy-cerda-zein'"
                            :to="`/agent/${member.attributes.Slug}`" 
                            :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile's profile`">
                            <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                        </router-link>
                        <p class="name" v-if="member.attributes.Slug === 'jose-cerda-zein' || member.attributes.Slug === 'nancy-cerda-zein'">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                        <p class="position-title" v-if="member.attributes.Slug === 'jose-cerda-zein'">
                            Broker
                        </p>
                        <p class="position-title" v-if="member.attributes.Slug === 'nancy-cerda-zein'">
                            Managing Partner
                        </p>
                    </div>
                </div>
                <div class="team-container">
                    <h2 class="team-title">
                        Sales Team
                    </h2>
                    <hr>
                    <div class="content">
                        <div v-for="(member, i) in featuredTeam('salesTeam')" :key="i" class="member">
                            <router-link
                                :to="`/agent/${member.attributes.Slug}`" 
                                :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile`">
                                <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                            </router-link>
                            <p class="name">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                            <p>{{member.attributes.Subtitle}}</p>
                        </div>
                    </div>
                </div>
                <div class="team-container">
                    <h2 class="team-title">
                        Leasing Team
                    </h2>
                    <hr>
                    <div class="content">
                        <div v-for="(member, i) in featuredTeam('leasingTeam')" :key="i" class="member">
                            <router-link 
                                :to="`/agent/${member.attributes.Slug}`" 
                                :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile`">
                                <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                            </router-link>
                            <p class="name">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                            <p>{{member.attributes.Subtitle}}</p>
                        </div>
                    </div>
                </div>
                <div class="team-container">
                    <h2 class="team-title">
                        Property Management Team
                    </h2>
                    <hr>
                    <div class="content">
                        <div v-for="(member, i) in featuredTeam('propertyManagementTeam')" :key="i" class="member">
                            <router-link 
                                :to="`/agent/${member.attributes.Slug}`" 
                                :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile`">
                                <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                            </router-link>
                            <p class="name">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                            <p>{{member.attributes.Subtitle}}</p>
                        </div>
                    </div>
                </div>
                <div class="team-container">
                    <h2 class="team-title">
                        Accounting Team
                    </h2>
                    <hr>
                    <div class="content">
                        <div v-for="(member, i) in featuredTeam('accountingTeam')" :key="i" class="member">
                            <router-link 
                                :to="`/agent/${member.attributes.Slug}`" 
                                :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile`">
                                <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                            </router-link>
                            <p class="name">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                            <p>{{member.attributes.Subtitle}}</p>
                        </div>
                    </div>
                </div>
                <div class="team-container">
                    <h2 class="team-title">
                        Client Care Team
                    </h2>
                    <hr>
                    <div class="content">
                        <div v-for="(member, i) in featuredTeam('clientCareTeam')" :key="i" class="member">
                            <router-link 
                                :to="`/agent/${member.attributes.Slug}`" 
                                :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile`">
                                <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                            </router-link>
                            <p class="name">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                            <p>{{member.attributes.Subtitle}}</p>
                        </div>
                    </div>
                </div>
                <div class="team-container">
                    <h2 class="team-title">
                        Project Management Team
                    </h2>
                    <hr>
                    <div class="content">
                        <div v-for="(member, i) in featuredTeam('projectManagementTeam')" :key="i" class="member">
                            <router-link 
                                :to="`/agent/${member.attributes.Slug}`" 
                                :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile`">
                                <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                            </router-link>
                            <p class="name">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                            <p>{{member.attributes.Subtitle}}</p>
                        </div>
                    </div>
                </div>
                <div class="team-container">
                    <h2 class="team-title">
                        Maintenance Team
                    </h2>
                    <hr>
                    <div class="content">
                        <div v-for="(member, i) in featuredTeam('maintenanceTeam')" :key="i" class="member">
                            <router-link 
                                :to="`/agent/${member.attributes.Slug}`" 
                                :aria-label="`Click to view agent ${member.attributes.FirstName} ${member.attributes.LastName}'s profile`">
                                <img :src="apiURL+member.attributes.Image.data.attributes.url" :alt="`${member.attributes.FirstName}  ${member.attributes.LastName}`" />
                            </router-link>
                            <p class="name">{{member.attributes.FirstName}} {{member.attributes.LastName}}</p>
                            <p>{{member.attributes.Subtitle}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: 'Team',
    components: {
        PageTitle
    },
    data(){
        return {
            title: 'Team',
            apiURL: 'https://strapi.cerda-zein.com',
            team:[],
        }
    },
    methods:{
        featuredTeam(teamStr){
            if(teamStr === 'special'){
                return this.team.filter((member) => {
                    console.log(member.attributes.Slug)
                    return member.attributes.Slug === 'jose-cerda-zein' || member.attributes.Slug === 'nancy-cerda-zein';
                })
            } else {
                return this.team.filter((member) => {
                    if (member.attributes.Team && member.attributes.Team.length !== 0){
                        return member.attributes.Team.includes(teamStr);
                    }
                    return false;
                }) 
            }
        }
    },
    mounted:function(){
        this.axios.get('https://strapi.cerda-zein.com/api/agents?populate=*&sort=rank:asc')
        .then((response) => {
            this.team = response.data.data;
            this.loading = !this.loading;
        })
    },
    metaInfo: {
        title: 'Team',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss" scoped>
.team{
    text-align: center;
    margin-bottom: 3rem;
    justify-content: center;
    h2 {
        text-align: center;
        font-size: 2.6rem;
        margin: 32px 56px;
    }
    .featured-members{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 48px auto;
    }
    .content{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .member{
        width: 25%;
        margin-bottom: 48px;
        a{
            img{
                display: block;
                object-fit: cover;
                max-height: 280px;
                max-width: 280px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin: 0 auto;
            }
        }
        .name{
            font-size: 2rem;
            font-family: ivymode, sans-serif;
        }
    }
    hr{
        width: 100%;
        margin: 32px auto;
        height: 1px;
        background: #ccc;
        border: none;
    }
}

@media (max-width: 1200px) {
    .team{
        .member{
            a{
                img{
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }
}
@media (max-width: $tablet) {
    .team{
        .member{
            width: 50%;
        }
    }
}
@media (max-width: $mobile) {
    .team{
        .content, .featured-members{
            .member{
                width: 100%;
            }
        }
    }
}
</style>
